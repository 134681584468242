<template>
  <div class="flex justify-between items-center">
    <app-heading>
      {{ t('models.notification', 2) }}
    </app-heading>
  </div>

  <template v-if="!loading">
    <template v-if="notifications.length > 0">
      <app-notification
        v-for="notification in notifications"
        :key="notification.id"
        v-bind="notification"
        class="my-4"
      />
    </template>

    <p
      v-else
      class="mt-2 text-center"
    >
      {{ t('notifications.index.no_results') }}
    </p>

    <template v-if="lastPage && page < lastPage">
      <a
        v-if="nextPageFetching === false"
        href="#"
        class="block text-center app-link w-full"
        @click.prevent="nextPage"
      >
        {{ t('common.actions.load_more') }}
      </a>

      <div
        v-else
        class="text-center w-full"
      >
        <app-spinner
          size="lg"
        />
      </div>
    </template>
  </template>

  <template v-else>
    <app-notification-card-empty
      v-for="i in 5"
      :key="i"
      class="my-4"
    />
  </template>
</template>

<script setup>
import { ref, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import {
  fetchNotifications as fetchApiNotifications,
  markNotificationsAsRead,
} from '@shared/http/api'
import AppHeading from '@app/components/ui/AppHeading.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import AppNotification from '@shared/components/features/notifications/AppNotification.vue'
import AppNotificationCardEmpty from '@shared/components/features/notifications/AppNotificationCardEmpty.vue'

const store = useStore()
const { t } = useI18n()

const nextPageFetching = ref(false)
const notifications = ref([])
const page = ref(1)
const lastPage = ref(null)
const loading = ref(false)

function fetchNotifications(nextPage = false) {
  if (nextPage === false) {
    loading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
  }

  fetchApiNotifications(params)
    .then((response) => {
      const oldNotifications = notifications.value
      const newNotifications = response.data.data?.map((notification) => ({
        id: notification.id,
        title: notification.attributes.title,
        text: notification.attributes.text,
        imageUrl: notification.attributes.image_url,
        source: notification.attributes.source,
        createdAgo: notification.attributes.created_ago,
        read: notification.attributes.read,
        type: notification.attributes.type,
      })) ?? []

      if (nextPage) {
        // Add new results after the old ones
        notifications.value = [...oldNotifications, ...newNotifications]
      } else {
        // Replace the old results with the new ones
        notifications.value = newNotifications
      }

      lastPage.value = response.data.meta?.last_page
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

function nextPage() {
  page.value += 1
  fetchNotifications(true)
}

function markAsRead() {
  const ids = notifications.value
    .filter((notification) => (!notification.read)) // only unread notifications
    .map((notification) => (notification.id))

  if (ids.length > 0) {
    markNotificationsAsRead({ ids })

    // Adapt unread notifications counter
    store.commit('auth/ADAPT_UNREAD_NOTIFICATIONS_COUNTER', -ids.length)
  }
}

onUnmounted(() => {
  markAsRead()
})

fetchNotifications()
</script>
