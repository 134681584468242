<template>
  <div>
    <router-link
      v-slot="{ navigate }"
      :to="sourceRoute"
      custom
    >
      <app-card-item
        :class="`break-words p-3 ${clickable ? 'cursor-pointer' : null }`"
        :shadow-classes="read ? 'shadow-none' : undefined"
        :background-classes="read ? 'bg-transparent' : undefined"
        @click="navigate"
      >
        <div class="flex">
          <!-- Image -->
          <div
            class="w-20 h-20 bg-cover bg-center mr-3 rounded-md"
            :style="`background-image: url(${imageUrlToUse});`"
          />

          <div class="w-9/12">
            <p class="text-sm font-bold">
              {{ title }}
            </p>

            <div class="text-xs">
              <p class="mb-3">
                {{ text }}
              </p>

              <div class="flex items-center">
                <app-icon
                  library="coolicon"
                  name="clock"
                  size="text-xs"
                  class="mr-0.5 text-theme-500"
                />
                <span>
                  {{ createdAgo }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </app-card-item>
    </router-link>
  </div>
</template>

<script setup>
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import useNotifications from '@shared/hooks/notifications'

const props = defineProps({
  // Type and id of the resource
  // related to the notification
  source: {
    type: Object,
    default: () => ({}),
  },
  // When the notification
  // was created
  createdAgo: {
    type: String,
    required: true,
  },
  // Notification has been read
  // or not
  read: {
    type: Boolean,
    default: null,
  },
  // Primary text displayed
  title: {
    type: String,
    default: null,
  },
  // Secondary text displayed
  text: {
    type: String,
    default: null,
  },
  // Image URL
  imageUrl: {
    type: String,
    default: null,
  },
  // Notification type
  type: {
    type: String,
    default: null,
  },
})

const {
  sourceRoute,
  clickable,
  imageUrlToUse,
} = useNotifications(props)

</script>
